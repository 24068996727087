import { graphql, useStaticQuery } from "gatsby";

const useExploreLanderData = () => {
  const data = useStaticQuery(graphql`
    query {
      data: allContentfulPageLayout(
        filter: { title: { eq: "Explore Lander" } }
      ) {
        nodes {
          heading
          shortDescription
          additionalText
          contentModules {
            ... on ContentfulImage {
              image {
                fluid {
                  srcSetWebp
                }
              }
              description
            }
            ... on ContentfulTheme20 {
              themeDescription {
                themeDescription
              }
              shortTheme
              themeNumber
              themeName: theme
            }
            ... on ContentfulFeaturedSection {
              heading
              description
              cta {
                linkText
                url
              }
              theme {
                theme
              }
            }
            ... on ContentfulLink {
              linkText
              url
            }
            ... on ContentfulUpcomingCoursesFeaturedBlogs {
              heading
              courses {
                ... on ContentfulBlogDetailPage {
                  title
                  urlCtaText
                  url
                  theme {
                    theme
                  }
                  blogImage {
                    fluid {
                      srcSetWebp
                      aspectRatio
                    }
                  }
                  blogImageDescription
                }
              }
            }
          }
          marketingModal {
            ... on ContentfulMarketingModal {
              id
              cta {
                linkText
                url
              }
              description {
                description
              }
              title
              showAfterPercent
              hubspotListId
              marketingModalImage {
                image {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                }
              }
              formFields
              successTitle
              successDescription
              additionalText {
                json
              }
            }
          }
        }
      }
    }
  `);
  return data.data.nodes[0];
};

export default useExploreLanderData;
