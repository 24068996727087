import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../utils/global-styles/theme";
import styled from "@emotion/styled";
import { determineTopicIcon } from "../../utils/services/methods";

const TopicCard = ({
  selectedTopic,
  shortTheme,
  themeNumber,
  themeName,
  totalTopics,
  setSelectedTopic,
}) => {
  const Wrapper = styled("div")`
      box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1);
      height: 202px;
      width: calc(100% / ${totalTopics});
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 26px;
      &:hover {
        cursor: ${themeNumber === selectedTopic ? "default" : "pointer"};
      }
      p {
        font-family: ${theme.fontFaces.headers};
        font-size: 24px;
        font-weight: 300;
        letter-spacing: -0.22px;
        line-height: 30px;
        text-align: center;
      }
    `,
    topicIconClasses =
      themeNumber === selectedTopic && shortTheme !== undefined
        ? `path-white-fill`
        : `path-primary-fill`;
  return (
    <Wrapper
      className={`${
        themeNumber === selectedTopic
          ? "primary-bg white"
          : "secondary-background-hover primary white-text-hover svg-path-white-fill-hover"
      }`}
      onClick={() => setSelectedTopic(themeNumber)}
    >
      {determineTopicIcon(themeNumber, topicIconClasses, "", "42px", "42px")}
      <p>{themeName}</p>
    </Wrapper>
  );
};

TopicCard.propTypes = {
  selectedTopic: PropTypes.number,
  themeDescription: PropTypes.shape({
    themeDescription: PropTypes.string,
  }),
  shortTheme: PropTypes.string,
  themeNumber: PropTypes.number,
  themeName: PropTypes.string,
  totalTopics: PropTypes.number,
};

export default TopicCard;
