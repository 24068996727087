import { graphql, useStaticQuery } from "gatsby";

const useExploreFeaturedBlogData = () => {
  const data = useStaticQuery(graphql`
    query {
      explore: allContentfulPageLayout(
        filter: { title: { eq: "Explore Lander" }  }
      ) {
        nodes {
          contentModules {
            ... on ContentfulUpcomingCoursesFeaturedBlogs {
              heading
              courses {
                ... on ContentfulBlogDetailPage {
                  title
                  theme {
                    theme
                    themeNumber
                  }
                  blogImage {
                    fluid {
                      srcSet
                      aspectRatio
                    }
                    description
                  }
                  url
                  urlCtaText
                }
              }
              linkText
              url
            }
          }
        }
      }
    }
  `);

  return data.explore.nodes[0].contentModules.find(node => node.heading);
};

export default useExploreFeaturedBlogData;
