import React, { useState } from "react";
import PropTypes from "prop-types";
import ExploreGridCard from "./ExploreGridCard";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { theme } from "../../utils/global-styles/theme";

const ExploreTopicGrid = ({ courses, blogs, selectedTopic }) => {
  const [topicCardLimit, setTopicCardLimit] = useState(12),
    [mobileTopicCardLimit, setMobileTopicCardLimit] = useState(3),
    sortedBlogs = blogs.filter(blog => blog.blogImage.fluid && blog.title && blog.url && blog.urlCtaText),
    stitchGrid = () => {
      const merged = [],
      l = Math.min(courses.length, sortedBlogs.length);
      for (let i = 0; i < l; i++) {
        if (i % 3 === 0) {
          merged.push(sortedBlogs[i], courses[i]);
        } else merged.push(courses[i]);
      }
      merged.push(...courses.slice(l), ...sortedBlogs.slice(l));
      return merged;
    };

    const stitchedGrid = stitchGrid(),
    gridLength = stitchedGrid.length,
    blogsInStitchedGrid = stitchedGrid.filter((item) => item.blogImage),
    difference = sortedBlogs.filter(blog => !blogsInStitchedGrid.includes(blog));
    stitchedGrid.push(...difference);

  const TopicGrid = styled.section`
      display: grid;
      grid-template: auto 1fr / 1fr;
      font-family: ${theme.fontFaces.headers};
      margin: 0px auto 120px auto;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin: 0px auto 50px auto;
      }
    `,
    TopicCardsContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
      }
    `,
    ViewMoreContainer = styled.div`
      grid-area: 2 / 1 / span 1 / span 1;
      display: flex;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 30px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    `,
    ViewMoreText = styled.h5`
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      cursor: pointer;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 16px;
      }
    `;

  return (
    <>
      {/* TopicGrid for desktop filtering limit, intervals of 12 */}
      <TopicGrid
        css={css`
          @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
            display: none;
          }
        `}
      >
        <TopicCardsContainer>
          {stitchedGrid
            .filter((cardData, i) => i < topicCardLimit)
            .map((cardData, i) => {
              return (
                <ExploreGridCard
                  key={i}
                  {...cardData}
                  isBlogCard={!cardData.courseType}
                  index={i}
                  selectedTopic={selectedTopic}
                />
              );
            })}
        </TopicCardsContainer>
        <ViewMoreContainer
          css={
            gridLength <= topicCardLimit &&
            css`
              display: none;
            `
          }
        >
          <ViewMoreText
            className="primary secondary-hover"
            onClick={() => setTopicCardLimit(topicCardLimit + 12)}
            tabIndex="0"
          >
            View More
          </ViewMoreText>
        </ViewMoreContainer>
      </TopicGrid>
      {/* TopicGrid for mobile filtering limit, intervals of 3 */}
      <TopicGrid
        css={css`
          display: none;
          @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
            display: grid;
          }
        `}
      >
        <TopicCardsContainer>
          {stitchGrid(courses, blogs)
            .filter((cardData, i) => i < mobileTopicCardLimit)
            .map((cardData, i) => {
              return (
                <ExploreGridCard
                  key={i}
                  {...cardData}
                  isBlogCard={!cardData.courseType}
                  index={i}
                  selectedTopic={selectedTopic}
                />
              );
            })}
        </TopicCardsContainer>
        <ViewMoreContainer
          css={
            gridLength <= mobileTopicCardLimit &&
            css`
              display: none;
            `
          }
        >
          <ViewMoreText
            className="primary secondary-hover"
            onClick={() => setMobileTopicCardLimit(mobileTopicCardLimit + 3)}
            tabIndex="0"
          >
            View More
          </ViewMoreText>
        </ViewMoreContainer>
      </TopicGrid>
    </>
  );
};

ExploreTopicGrid.propTypes = {
  courses: PropTypes.object,
  blogs: PropTypes.object,
  selectedTopic: PropTypes.string.isRequired,
};

export default ExploreTopicGrid;
