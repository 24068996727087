import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { theme } from "../utils/global-styles/theme";
import styled from "@emotion/styled";
import get from "lodash/get";

import ExploreLanderHero from "../components/ExploreLanderHero/ExploreLanderHeroSection";
import TopicCard from "../components/Explore/TopicCard";
import TopicMobileDropdown from "../components/Explore/TopicMobileDropdown";
import BecomeAFellow from "../components/BecomeAFellow/BecomeAFellow";
import ExploreTopicGrid from "../components/Explore/ExploreTopicGrid";
import FeaturedArticle from "../components/Explore/FeaturedArticle";

import useExploreLanderData from "../hooks/explore-lander-data";
import {
  useRedefineSuccessTopicCardsData,
  useGrowAsALeaderTopicCardsData,
  useImagineTopicCardsData,
  useFundTopicCardsData,
  useBuildBusinessTopicCardsData,
  useMasterTopicCardsData,
} from "../hooks/explore-lander-topic-cards-data";
import useExploreFeaturedBlogData from "../hooks/explore-featured-blog-data";

// Explore lander
const ExploreLanderPage = () => {
  const [selectedTopic, setSelectedTopic] = useState(5),
    [loading, setLoading] = useState(false),
    data = useExploreLanderData(),
    featuredBlogData = useExploreFeaturedBlogData(),
    topicCardsData = data.contentModules.filter(module => module.themeNumber),
    loadTopicData = topicNumber => {
      switch (parseInt(topicNumber)) {
        case 1:
          return useRedefineSuccessTopicCardsData();
        case 2:
          return useImagineTopicCardsData();
        case 3:
          return useFundTopicCardsData();
        case 4:
          return useBuildBusinessTopicCardsData();
        case 5:
          return useMasterTopicCardsData();
        default:
          return useGrowAsALeaderTopicCardsData();
      }
    },
    TopicCardWrapper = styled("section")`
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        display: none;
      }
    `,
    TopicHeaderWrapper = styled("div")`
      display: grid;
      grid-template: 45px auto 45px / 22.5% 1fr 22.5%;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template: 45px auto 34px / 14px 1fr 14px;
      }
    `,
    TopicHeaderContent = styled("div")`
      grid-area: 2 / 2 / span 1 / span 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    `,
    TopicTitle = styled("h2")`
      font-size: 30px;
      letter-spacing: -0.09px;
      line-height: 30px;
      margin-bottom: 18px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 20px;
        letter-spacing: -0.06px;
        line-height: 21px;
      }
    `,
    TopicDescription = styled("h3")`
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -0.45px;
      line-height: 32px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 18px;
        letter-spacing: -0.39px;
        line-height: 26px;
      }
    `,
    currentTopicData = loadTopicData(selectedTopic);
    
  return (
    <Layout emptyHelpSection={true} loading={loading} marketingModal={data.marketingModal}>
      <ExploreLanderHero />
      <TopicCardWrapper>
        {topicCardsData.map((topic, i) => (
          <TopicCard
            key={i}
            selectedTopic={selectedTopic}
            setSelectedTopic={setSelectedTopic}
            {...topic}
            totalTopics={topicCardsData.length}
          />
        ))}
      </TopicCardWrapper>
      <TopicMobileDropdown
        topics={topicCardsData}
        selectedTopic={selectedTopic}
        setSelectedTopic={setSelectedTopic}
      />
      <TopicHeaderWrapper>
        <TopicHeaderContent>
          <TopicTitle>
            {
              topicCardsData.find(topic => topic.themeNumber === selectedTopic)
                .themeName
            }
          </TopicTitle>
          <TopicDescription id="description">
            {
              topicCardsData.find(topic => topic.themeNumber === selectedTopic)
                .themeDescription.themeDescription
            }
          </TopicDescription>
        </TopicHeaderContent>
      </TopicHeaderWrapper>
      <ExploreTopicGrid
        courses={currentTopicData.courses}
        blogs={currentTopicData.blogs}
        selectedTopic={selectedTopic}
      />
      {selectedTopic === 6 && <BecomeAFellow />}
      <FeaturedArticle
        heading={get(featuredBlogData, "heading", "Explore More")}
        ctaText={get(featuredBlogData, "linkText", "READ MORE ARTICLES")}
        ctaUrl={get(featuredBlogData, "url", "/blog")}
        blogContent={get(currentTopicData, "featuredBlogs.nodes[0]", null)}
      />
    </Layout>
  );
};

export default ExploreLanderPage;
