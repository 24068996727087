import { graphql, useStaticQuery } from "gatsby";

const useFundTopicCardsData = () => {
  const data = useStaticQuery(graphql`
    query {
      courses: allContentfulCourseDetailPage(
        filter: {
          theme20: { themeNumber: { eq: 3 } }
          node_locale: { eq: "en-US" }
        }
      ) {
        nodes {
          courseType
          courseImage {
            fluid {
              srcSet
              aspectRatio
            }
          }
          slug
          title
          courseCost
          courseAvailability
          courseDuration
        }
      }
      blogs: allContentfulBlogDetailPage(
        filter: {
          theme: { themeNumber: { eq: 3 } }
          languageCode: { eq: "en" }
          title: {ne: null }
        }
      ) {
        nodes {
          blogImage {
            fluid {
              aspectRatio
              srcSet
            }
          }
          title
          urlCtaText
          url
        }
      }
      featuredBlogs: allContentfulTheme20(filter: { themeNumber: { eq: 3 } }) {
        nodes {
          themeNumber
          theme
          featuredArticle {
            title
            Subtitle
            blogImage {
              fluid {
                srcSet
                aspectRatio
              }
            }
            url
            urlCtaText
            blogImageCaption
            blogImageDescription
          }
        }
      }
    }
  `);

  const cards = {
    courses:
      data && data.courses && data.courses.nodes ? data.courses.nodes : [],
    blogs: data && data.blogs && data.blogs.nodes ? data.blogs.nodes : [],
    featuredBlogs: data && data.featuredBlogs ? data.featuredBlogs : [],
  };
  return cards;
};

export default useFundTopicCardsData;
