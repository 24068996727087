import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { theme } from "../../utils/global-styles/theme";
import { css } from "@emotion/core";
import { determineTopicIcon } from "../../utils/services/methods";
import DownArrowIcon from "../../components/shared/DownArrowIcon";

const TopicMobileDropdown = ({ topics, selectedTopic, setSelectedTopic }) => {
  const [overlayActive, setOverlayActive] = useState(false);

  useEffect(() => {
    const scrollTop = 0,
      scrollLeft = 0;

    return (window.onscroll = overlayActive
      ? () => window.scrollTo(scrollLeft, scrollTop)
      : function() {});
  }, [overlayActive]);

  const DropdownContainer = styled.section`
      background-color: ${theme.colors.light};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      @media only screen and (min-width: ${theme.breakpoints.helper1100}) {
        display: none;
      }
    `,
    ExploreTopicTextContainer = styled.div`
      width: 92%;
      height: 24px;
      font-family: ${theme.fontFaces.headers};
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      text-transform: uppercase;
      margin-top: 20px;
    `,
    DropdownDisplayGrid = styled.div`
      width: 92%;
      height: 43px;
      margin: 6px 0px 27px 0px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.39px;
      line-height: 26px;
      background-color: ${theme.colors.white};
      font-family: ${theme.fontFaces.headers};
      display: grid;
      grid-template: 1fr / 90% 10%;
      cursor: pointer;
    `,
    ThemeIconAndTextContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      font-size: 21px;
      font-weight: 500;
      letter-spacing: -0.45px;
      line-height: 32px;
      display: flex;
      align-items: center;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: -0.39px;
        line-height: 26px;
      }
    `,
    DownArrowContainer = styled.div`
      grid-area: 1 / 2 / span 1 / span 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 12px;
    `,
    FixedCancelMenuContainer = styled.div`
      height: 47px;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${theme.colors.light};
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 18px;
      z-index: 11;
    `,
    CancelText = styled.h4`
      font-size: 13px;
      font-weight: bold;
      letter-spacing: -0.28px;
      line-height: 16px;
      text-transform: uppercase;
      border-bottom: 3px solid;
      width: max-content;
      cursor: pointer;
    `;

  return (
    <DropdownContainer>
      <ExploreTopicTextContainer className="primary">
        Explore A Topic
      </ExploreTopicTextContainer>
      <DropdownDisplayGrid
        onClick={() => {
          window.scroll({ top: 0, left: 0 });
          setOverlayActive(true);
        }}
      >
        <ThemeIconAndTextContainer>
          {determineTopicIcon(
            selectedTopic,
            "path-primary-fill",
            "margin: 0px 7px;",
            `25px`,
            `25px`
          )}
          {topics.find(topic => topic.themeNumber === selectedTopic).themeName}
        </ThemeIconAndTextContainer>
        <DownArrowContainer>
          <DownArrowIcon classNames={"path-primary-fill"} />
        </DownArrowContainer>
      </DropdownDisplayGrid>
      {overlayActive && (
        <>
          <ul
            css={css`
              position: fixed;
              top: 61px;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: ${theme.colors.white};
              z-index: 10;
            `}
          >
            {topics.map((topic, index) => (
              <li
                key={index}
                onClick={() => {
                  setOverlayActive(false);
                  setSelectedTopic(topic.themeNumber);
                }}
                css={css`
                  height: 54px;
                  font-size: 20px;
                  font-weight: bold;
                  letter-spacing: -0.06px;
                  line-height: 21px;
                  font-family: ${theme.fontFaces.headers};
                  display: flex;
                  align-items: center;
                  border-top: 1px solid ${theme.colors.light};
                  border-bottom: 1px solid ${theme.colors.light};
                `}
              >
                {determineTopicIcon(
                  topic.themeNumber,
                  "path-primary-fill",
                  "margin: 0px 10px 0px 15px;",
                  `19px`,
                  `19px`
                )}
                {topic.themeName}
              </li>
            ))}
          </ul>
          <FixedCancelMenuContainer>
            <CancelText
              className="primary primary-border"
              onClick={() => setOverlayActive(false)}
            >
              Cancel
            </CancelText>
          </FixedCancelMenuContainer>
        </>
      )}
    </DropdownContainer>
  );
};

TopicMobileDropdown.propTypes = {
  topics: PropTypes.arrayOf(PropTypes.object),
  selectedTopic: PropTypes.number,
  setSelectedTopic: PropTypes.func,
};

export default TopicMobileDropdown;
