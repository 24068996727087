import React from "react";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import get from "lodash/get";

import { theme } from "../../utils/global-styles/theme";

import useExploreHeroData from "../../hooks/explore-hero-data";

const ExploreLanderHeroSection = () => {
  const data = useExploreHeroData(),
    HeroGrid = styled.section`
      display: grid;
      grid-template: 1fr / 1fr;
      max-height: 588px;
      font-family: ${theme.fontFaces.headers};
    `,
    HeroImage = styled(Img)`
      grid-area: 1 / 1 / span 1 / span 1;
    `,
    HeroTextContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 1;
      background: linear-gradient(
        350.52deg,
        rgba(50, 50, 50, 0) 0%,
        rgba(50, 50, 50, 0.3) 100%
      );
      padding-top: 120px;
      padding-left: 120px;
      z-index: 1;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-top: 100px;
        padding-left: 14px;
      }
    `,
    TitleContainer = styled.h2`
      margin-bottom: 30px;
      font-size: 130px;
      letter-spacing: -6.25px;
      line-height: 130px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 40px;
        letter-spacing: -1.92px;
        line-height: 40px;
        margin-bottom: 8px;
      }
    `,
    DescriptionContainer = styled.h3`
      margin-bottom: 50px;
      font-size: 53px;
      letter-spacing: -1.38px;
      line-height: 53px;
      max-width: 880px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        margin-bottom: 20px;
        font-size: 20px;
        letter-spacing: -0.52px;
        line-height: 24px;
        max-width: 480px;
      }
    `,
    AddTextContainer = styled.div`
      font-size: 20px;
      letter-spacing: 0.57px;
      line-height: 15px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 50px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 13px;
        letter-spacing: -0.28px;
        line-height: 16px;
        margin-bottom: 30px;
      }
    `;

  return (
    <HeroGrid className="white primary-bg">
      {data.heroImage.image && data.heroImage.image.fluid && (
        <HeroImage
          fluid={data.heroImage.image.fluid}
          alt={data.heroImage.image.description}
        />
      )}
      <HeroTextContainer>
        <TitleContainer>{get(data, "heading", "Explore")}</TitleContainer>
        <DescriptionContainer>
          {get(
            data,
            "shortDescription",
            "Find the skills you need to make a difference."
          )}
        </DescriptionContainer>
        <AddTextContainer>
          {get(data, "additionalText", "Select Your Topic Below")}
        </AddTextContainer>
      </HeroTextContainer>
    </HeroGrid>
  );
};

export default ExploreLanderHeroSection;
