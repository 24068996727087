import { graphql, useStaticQuery } from "gatsby";
import merge from "lodash/merge";

const useExploreHeroData = () => {
  const data = useStaticQuery(graphql`
    query {
      explore: allContentfulPageLayout(
        filter: { title: { eq: "Explore Lander" } }
      ) {
        nodes {
          heading
          shortDescription
          additionalText
          contentModules {
            ... on ContentfulImage {
              image {
                fluid {
                  srcSet
                  aspectRatio
                }
                description
              }
            }
          }
        }
      }
    }
  `);
  const { heading, shortDescription, additionalText } = data.explore.nodes[0];
  return {
    heading,
    shortDescription,
    additionalText,
    heroImage: data.explore.nodes[0].contentModules.find(
      contentModule => contentModule.image.fluid
    ),
  };
};

export default useExploreHeroData;
