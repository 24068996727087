import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { theme } from "../../utils/global-styles/theme";
import FellowBackgroundImage from "./child-components/FellowBackgroundImage";
import VideoOverlay from "./child-components/VideoOverlay";
import VideoIcon from "./assets/VideoIcon";
import useBecomeAFellowData from "../../hooks/become-a-fellow";

const SectionContainer = styled.section`
    position: relative;
    padding: 50px 0;
    margin-top: 6em;
    margin-bottom: 1em;

    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      margin-top: 1em;
      margin-bottom: 0;
      h2 {
        padding-left: 0.8em;
        font-size: 8em;
        top: 0;
      }
    }
  `,
  WatchVideoLinkContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 1em;
    padding-left: 1em;
    cursor: pointer;
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      padding-left: 2em;
    }
  `,
  WatchVideoLink = styled.div`
    text-transform: uppercase;
    border-bottom: 4px solid;
    font-weight: bold;
    margin-left: 0.5em;
    font-family: ${theme.fontFaces.headers};
  `,
  StyledImage = styled(Img)`
    height: 300px;
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      display: none;
    }
  `,
  Desktoph2 = styled.h2`
    @media only screen and (max-width: ${theme.breakpoints.mobile}) {
      display: none;
    }
    line-height: 1em;
    position: absolute;
    z-index: 1;
  `,
  Mobileh2 = styled.h2`
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      display: none;
    }
    position: absolute;
    z-index: 1;
    font-size: 2.3em;
    ${theme.paddings.mobileLR}
    top: 6%;
  `,
  WatchVideoImageAndLink = styled.div`
    @media only screen and (min-width: ${theme.breakpoints.mobile}) {
      position: absolute;
      width: 40%;
      top: 55%;
      right: 3%;
    }
  `,
  BackgroundImageStyles = css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.white};
    padding: 2em 5em 3.5em 1em;
    h3 {
      font-size: 1.25em;
      padding-bottom: 1em;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }
    a {
      text-transform: uppercase;
      color: ${theme.colors.white};
      border-bottom: 4px solid;
      font-weight: bold;
    }
    @media only screen and (min-width: ${theme.breakpoints.helper1100}) {
      height: 600px;
      h3 {
        font-size: 2em;
        padding: 3em 13em 1em 2em;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
      }
      a {
        margin-left: 4em;
      }
    }
  `,
  BecomeAFellow = ({ locale, becomeAFellowData }) => {
    const [showOrRemoveVideoOverlay, setShowOrRemoveVideoOverlay] = useState(
        false
      ),
      {
        featuredSectionDescription,
        heading,
        cta,
        videoStillImage,
        watchVideoCta,
      } = becomeAFellowData,
      firstWordOfHeader = heading.substr(0, heading.indexOf(" ")),
      wordsAfterTheFirst = heading.substr(firstWordOfHeader.length),
      closeVideoOverlay = () => {
        setShowOrRemoveVideoOverlay(false);
      };
    return (
      <SectionContainer>
        <VideoOverlay
          show={showOrRemoveVideoOverlay}
          closeVideoOverlay={closeVideoOverlay}
          videoUrl={watchVideoCta.url}
          title={heading}
        />
        <Desktoph2>{heading}</Desktoph2>
        <Mobileh2>{heading}</Mobileh2>
        <div
          css={css`
            @media only screen and (min-width: ${theme.breakpoints.mobile}) {
              padding: 10em 5em 10em 3em;
            }
          `}
        >
          <div>
            Add something here
          </div>
          {/* <FellowBackgroundImage
            child={
              <>
                <h3>{featuredSectionDescription}</h3>
                <Link to={`${cta.url}/`}>{cta.linkText}</Link>
              </>
            }
            styles={BackgroundImageStyles}
          ></FellowBackgroundImage> */}
          <div>
            <WatchVideoImageAndLink>
              <StyledImage
                fluid={videoStillImage.image.fluid}
                alt={videoStillImage.description}
              />
              <WatchVideoLinkContainer
                onClick={() => setShowOrRemoveVideoOverlay(true)}
              >
                <VideoIcon />
                <WatchVideoLink tabIndex="0" className="primary">
                  {watchVideoCta.linkText}
                </WatchVideoLink>
              </WatchVideoLinkContainer>
            </WatchVideoImageAndLink>
          </div>
        </div>
      </SectionContainer>
    );
  };

BecomeAFellow.prototype = {
  locale: PropTypes.string,
};
BecomeAFellow.defaultProps = {
  locale: `en-US`,
};

export default BecomeAFellow;
