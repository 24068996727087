import React from "react";
import PropTypes from "prop-types";
import { theme } from "../../utils/global-styles/theme";
import { css } from "@emotion/core";
import { Link, navigate } from "gatsby";
import Img from "gatsby-image";
import styled from "@emotion/styled";
import { determineTopicIcon } from "../../utils/services/methods";

const ExploreGridCard = ({
  title,
  urlCtaText,
  url,
  blogImage,
  isBlogCard,
  courseType,
  courseCost,
  courseAvailability,
  courseDuration,
  courseImage,
  slug,
  index,
  selectedTopic,
}) => {
  const CardWrapper = styled("div")`
      width: 33.33%;
      box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        width: 375px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        width: 100%;
      }
    `,
    ImageCard = styled("div")`
      height: 300px;
      width: 100%;
      background-color: ${theme.colors.ltGray};

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        height: 234px;
      }
    `,
    CardContentWrapper = styled("div")`
      height: 300px;
      display: grid;
      grid-template: 40px minmax(0, 1fr) 30px / 80px minmax(0, 1fr) 90px;

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        grid-template-columns: 60px minmax(0, 1fr) 45px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-columns: 60px minmax(0, 1fr) 45px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        height: 234px;
      }
    `,
    CardTypeIdentifier = styled("p")`
      margin-bottom: 10px;
      font-size: 17px;
      font-weight: bold;
      line-height: 20px;
      font-family: ${theme.fontFaces.agStandardBold};
      text-transform: uppercase;
    `,
    CardTitle = styled("h4")`
      font-size: 35px;
      font-weight: bold;
      letter-spacing: -0.91px;
      line-height: 35px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.52px;
        line-height: 24px;
      }
    `,
    LinkText = styled(Link)`
      font-size: 17px;
      font-weight: bold;
      letter-spacing: -0.37px;
      line-height: 20px;
      border-bottom-width: 3px;
      border-bottom-style: solid;
      text-transform: uppercase;
      position: absolute;
      bottom: 0;
    `,
    CardSpecificsWrapper = styled("div")`
      grid-area: 2 / 2 / span 1 / span 1;
      position: relative;
    `,
    TopicIconWrapper = styled("div")`
      display: flex;
      grid-area: 2 / 1 / span 1 / span 1;
      justify-content: center;
    `,
    CourseCardDetailsWrapper = styled("div")`
      width: 100%;
      position: absolute;
      bottom: 0;
      display: flex;
    `,
    CourseCardDetailsText = styled("p")`
      font-size: 14.5px;
      font-weight: bold;
      line-height: 16px;
      border-top-color: ${index % 2 === 0 ? "" : theme.colors.white};
      border-top-width: 4px;
      border-top-style: solid;
      padding-top: 4px;
      font-family: ${theme.fontFaces.agStandardBold};
      margin-right: 30px;
    `;

  return isBlogCard ? (
    <CardWrapper
      className={
        "link-container-secondary-hover secondary-border-hover__child__anchor"
      }
      onClick={() => {
        navigate(`/blog${url}/`);
      }}
      tabIndex="0"
    >
      {blogImage && blogImage.fluid && blogImage.fluid.srcSet ? (
        <Img
          css={css`
            height: 300px;
          `}
          fluid={blogImage.fluid}
        />
      ) : (
        <ImageCard></ImageCard>
      )}
      <CardContentWrapper>
        <TopicIconWrapper>
          {determineTopicIcon(
            selectedTopic,
            "path-secondary-fill",
            null,
            "24px",
            "24px"
          )}
        </TopicIconWrapper>
        <CardSpecificsWrapper>
          <CardTypeIdentifier>BLOG</CardTypeIdentifier>
          <CardTitle
            css={css`
              @media only screen and (max-width: ${theme.breakpoints
                  .helper1100}) {
                display: none;
              }

              @media only screen and (max-width: ${theme.breakpoints.mobile}) {
                display: block;
              }
            `}
          >
            {title.length >= 46 ? title.slice(0, 46).trim() + "..." : title}
          </CardTitle>
          <CardTitle
            css={css`
              display: none;

              @media only screen and (max-width: ${theme.breakpoints
                  .helper1100}) {
                display: block;
              }

              @media only screen and (max-width: ${theme.breakpoints.mobile}) {
                display: none;
              }
            `}
          >
            {title}
          </CardTitle>
          <LinkText to={`/blog${url}/`} className="main">
            {urlCtaText}
          </LinkText>
        </CardSpecificsWrapper>
      </CardContentWrapper>
    </CardWrapper>
  ) : (
    <CardWrapper
      className={
        index % 2 === 0
          ? "secondary-hover secondary-border-hover__child__anchor"
          : "primary-bg white secondary-background-hover"
      }
      onClick={() => {
        navigate(`/course/${slug}/`);
      }}
        tabIndex="0"
    >
      {courseImage && courseImage.fluid && courseImage.fluid.srcSet ? (
        <Img
          css={css`
            height: 300px;
          `}
          fluid={courseImage.fluid}
        />
      ) : (
        <ImageCard></ImageCard>
      )}
      <CardContentWrapper>
        <TopicIconWrapper>
          {determineTopicIcon(
            selectedTopic,
            index % 2 === 0 ? "path-secondary-fill" : "path-white-fill",
            null,
            "24px",
            "24px"
          )}
        </TopicIconWrapper>
        <CardSpecificsWrapper>
          <CardTypeIdentifier>{courseType}</CardTypeIdentifier>
          <CardTitle>{title}</CardTitle>
          <CourseCardDetailsWrapper>
            <CourseCardDetailsText>{courseCost}</CourseCardDetailsText>
            <CourseCardDetailsText>{courseAvailability}</CourseCardDetailsText>
            <CourseCardDetailsText>{courseDuration}</CourseCardDetailsText>
          </CourseCardDetailsWrapper>
        </CardSpecificsWrapper>
      </CardContentWrapper>
    </CardWrapper>
  );
};
//TODO: Ryeker add the show more link and pagination
//TODO: Ryeker add mobile styles

ExploreGridCard.propTypes = {
  title: PropTypes.string,
  urlCtaText: PropTypes.string,
  url: PropTypes.string,
  isBlogCard: PropTypes.bool,
  courseType: PropTypes.string,
  courseCost: PropTypes.string,
  courseAvailability: PropTypes.string,
  courseDuration: PropTypes.string,
  courseImage: PropTypes.shape({
    fluid: PropTypes.shape({
      srcSetWebp: PropTypes.string,
      aspectRatio: PropTypes.number,
    }),
  }),
  slug: PropTypes.string,
  blogImage: PropTypes.shape({
    fluid: PropTypes.shape({
      srcSetWebp: PropTypes.string,
      aspectRatio: PropTypes.number,
    }),
  }),
  selectedTopic: PropTypes.number.isRequired,
};

export default ExploreGridCard;
